<template>
  <div class="page-container">
    <Back v-bind:url="'#/ServiceRequests'" v-bind:title="title"></Back>
    <div class="section-main">
      <div class="request-holder">
        <div class="request-info">
          <div class="request-row">
            <span class="request-name request-name-status">
              <span class="request-name">Дата</span>
              <span
                class="request-status"
                v-bind:class="allResult.statusClass"
                >{{ allResult.status }}</span
              >
            </span>
            <span class="request-val">
              <span>{{ allResult.date }}</span>
              <div class="rating-holder" v-if="ratingVal > 0">
                <span
                  v-for="(item, i) in stars"
                  :key="i"
                  class="icon ic-star"
                  :class="{ 'ic-star-active': i < ratingVal }"
                ></span>
              </div>
            </span>
          </div>

          <div class="request-row">
            <span class="request-name">{{
              $ml.with("VueJS").get("techName")
            }}</span>
            <span class="request-val"
              >{{ allResult.technicians }} {{ allResult.brand }}
              {{ allResult.model }}</span
            >
          </div>
          <div class="request-row" v-if="allResult.serial">
            <span class="request-name">{{
              $ml.with("VueJS").get("serialNum")
            }}</span>
            <span class="request-val">{{ allResult.serial }}</span>
          </div>
          <div
            class="request-row"
            v-if="allResult.description && allResult.description != 'null'"
          >
            <span class="request-name">{{
              $ml.with("VueJS").get("shortDesc")
            }}</span>
            <span class="request-val">{{ allResult.description }}</span>
          </div>
          <div
            class="request-row"
            v-else-if="allResult.repair && allResult.repair != 'null'"
          >
            <span class="request-name">{{
              $ml.with("VueJS").get("shortDesc")
            }}</span>
            <span class="request-val">{{ allResult.repair }}</span>
          </div>
        </div>
      </div>
      <div class="discuss-holder">
        <div class="discuss-title">
          {{ $ml.with("VueJS").get("discusOfApp") }}
        </div>
        <template v-for="requestdd in allResultMsg">
          <div :key="requestdd.locationCode" class="discuss-item">
            <div class="discuss-row">
              <span class="discuss-name">{{ requestdd.author }}</span>
            </div>
            <div class="discuss-row">
              <span class="discuss-date">{{ requestdd.date }}</span>
            </div>
            <div class="discuss-row" v-html="requestdd.msg"></div>
            <div v-if="requestdd.files.length !== 0" class="discuss-row">
              <ul class="request-file-list">
                <li class="request-file">
                  <img v-bind:src="requestdd.files.href" alt="Img" />
                </li>
              </ul>
            </div>
          </div>
        </template>
        <div class="discuss-form-holder">
          <form
            @submit.prevent="formSend()"
            id="upload-form"
            name="uploadForm"
            method="post"
            enctype="multipart/form-data"
          >
            <div
              class="form-group"
              v-bind:class="{ 'form-group-error': $v.textComment.$error }"
            >
              <textarea
                id="text_comment"
                name="text"
                class="textarea textarea-sm"
                v-model="textComment"
                @focus="scrollButton()"
                @input="$v.textComment.$touch()"
                @blur="$v.textComment.$touch()"
                v-bind:class="{ 'has-value': $v.textComment.$model }"
                :placeholder="$ml.with('VueJS').get('сomment')"
              ></textarea>

              <span
                class="icon icon-error"
                aria-label="icon error"
                v-if="$v.textComment.$error"
              ></span>
              <div class="error" v-if="$v.textComment.$error">
                {{ $ml.with("VueJS").get("setComment") }}
              </div>
            </div>
            <div class="form-group">
              <div class="file-holder">
                <div
                  v-for="(file, key) in files"
                  :key="key.id"
                  class="file-listing"
                >
                  <img class="preview" v-bind:ref="'image' + parseInt(key)" />
                  <span
                    class="file-remove"
                    v-on:click="removeImage(key)"
                  ></span>
                </div>
              </div>
              <label class="input-file-label">
                <input
                  type="file"
                  id="files"
                  ref="files"
                  accept="image/*"
                  name="file"
                  multiple
                  v-on:change="handleFilesUpload()"
                />
                <i class="icon icon-file"></i>
                <div class="input-file-text">
                  {{ $ml.with("VueJS").get("setFile") }}
                </div>
              </label>
            </div>
            <div class="form-group">
              <button class="btn btn-link" type="submit">
                <i class="icon icon-send"></i>
                <span>{{ $ml.with("VueJS").get("send") }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <modal-rating :onLogin="onLogin"></modal-rating>
    <div class="section-bottom">
      <template v-if="allResult.statusId === '4'">
        <button
          v-if="ratingVal == 0"
          class="btn btn-full-width btn-main"
          @click="$modal.show('modal-rating')"
        >
          {{ $ml.with("VueJS").get("rateService") }}
        </button>
      </template>
      <a v-else v-bind:href="callPhone" class="btn btn-full-width btn-main">{{
        $ml.with("VueJS").get("callDispatcher")
      }}</a>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/dist/validators.min";
import Back from "../Back";
import ModalRating from "../modal/ModalRating";

export default {
  components: {
    Back,
    "modal-rating": ModalRating,
  },
  data() {
    return {
      returnChat: {},
      allResult: {},
      allResultMsg: [],
      callPhone: "tel:0673115052",
      title: "Заявка ",
      files: [],
      textComment: null,
      requestForm: null,
      ratingVal: 0,
      stars: [1, 2, 3, 4, 5],
    };
  },
  validations: {
    textComment: {
      required,
      minLength: minLength(2),
    },
  },
  mounted() {
    if (navigator.onLine) {
      caches
        .keys()
        .then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
          });
        })
        .then(() => {
          console.log("Cache cleared");
        });
    }
    this.title = "Заявка № " + this.$route.params["id"];
    this.loadJsonService();
    this.loadJsonServiceMsg();
    this.loadJsonCallPhone();
  },
  computed: {
    messageList() {
      return this.allResultMsg;
    },
  },
  methods: {
    scrollButton() {
      window.scrollTo(0, 999);
    },
    loadJsonCallPhone() {
      this.$http
        // .get(
        //   "https://nfm.com.ua/index.php?route=api/app/users/" +
        //     localStorage.userId
        // )
        .get(
          "https://dev3221.nfm.com.ua/index.php?route=api/app/users/" +
            localStorage.userId
        )
        .then((response) => response.json())
        .then((response) => {
          if (response.manager_phone) {
            this.callPhone = "tel:" + response.manager_phone;
          }
          if (!this.resultUser.can_use_app) {
            localStorage.clear();
          }
          // console.log(response)
        });
    },
    loadJsonServiceMsg() {
      var resultt = "";
      this.allResultMsg = [];
      this.serviceMsg
        .get()
        .then((response) => response.json())
        .then((response) => {
          for (resultt in response) {
            // if (response[resultt].num === this.$route.params['id']) {
            this.allResultMsg.push(response[resultt]);
            // }
          }
        });
    },
    loadJsonService() {
      var resultt = "";
      this.service
        .get()
        .then((response) => response.json())
        .then((response) => {
          for (resultt in response) {
            if (response[resultt].num === this.$route.params["id"]) {
              if (response[resultt].brand === "null") {
                response[resultt].brand = "";
              }
              if (response[resultt].model === "null") {
                response[resultt].model = "";
              }
              if (response[resultt].technicians === "null") {
                response[resultt].technicians = "";
              }
              if (response[resultt].serial === "null") {
                response[resultt].serial = "";
              }
              let status = response[resultt].statusId;
              switch (status) {
                case "1":
                  response[resultt].statusClass = "status-new";
                  break;
                case "2":
                  response[resultt].statusClass = "status-inwork";
                  break;
                case "3":
                  response[resultt].statusClass = "status-canceled";
                  break;
                case "4":
                  response[resultt].statusClass = "status-done";
                  break;
                default:
                  response[resultt].statusClass = "status-new";
                  break;
              }
              this.allResult = response[resultt];
              console.log(this.allResult);
              this.ratingVal = parseInt(this.allResult.mark);
              if (!this.ratingVal) {
                this.ratingVal = 0;
              }
              console.log(this.ratingVal);

              let date = new Date(this.allResult.date);
              let monthstr = "";
              let month = parseInt(date.getMonth() + 1);
              if (month <= 9) {
                monthstr = "0" + month;
              } else {
                monthstr = "" + month;
              }

              let datestr = "";
              let datedate = date.getDate();
              if (datedate <= 9) {
                datestr = "0" + datedate;
              } else {
                datestr = "" + datedate;
              }
              this.allResult.date =
                datestr + "." + monthstr + "." + date.getFullYear();

              // console.log(this.allResult.date)
            }
          }
        });
    },
    formSend() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.$v.$reset();
        // this.files = []
        // this.textComment = ''
        this.submitStatus = "PENDING";
        var formdata = new FormData();
        formdata.append("service_id", this.$route.params["id"]);
        formdata.append("author", localStorage.userId);
        formdata.append("msg", this.textComment);

        if (this.$refs.files.files[0]) {
          // for (let i = 0; this.files.length; i++) {
          //   formdata.append('files[' + i + ']', this.files[i])
          // }
          formdata.append(
            "files",
            this.$refs.files.files[0],
            this.$refs.files.files[0].name
          );
        }
        // console.log(this.$route.params['id'])
        // console.log(localStorage.userId)
        // console.log(this.$route.params['id'])
        // console.log(formdata)
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        console.log(requestOptions);
        fetch(
          "https://nfm.com.ua/index.php?route=api/app/service_msg/",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            this.loadJsonServiceMsg();
          })
          .catch((error) => console.log("error", error));
        this.textComment = "";
        // this.$refs.files = []
        this.files = [];

        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }

      this.getImagePreviews();
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/\.(jpe?g|png|gif|zip|rar|pgf|txt)$/i.test(this.files[i].name)) {
          let reader = new FileReader();

          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );

          reader.readAsDataURL(this.files[i]);
        }
      }
    },
    removeImage(key) {
      this.files.splice(key, 1);
    },
    onLogin(data) {
      this.ratingVal = data.ratingVal;
    },
  },
  created() {
    this.service = this.$resource("service/ua/" + localStorage.userId);
    this.serviceMsg = this.$resource("service_msg/" + this.$route.params["id"]);
    console.log(this.serviceMsg);
  },
};
</script>
<style scoped>
.page-container {
  height: auto;
}
</style>
