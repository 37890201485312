<template>
  <div
    class="main-index-container"
    v-bind:class="{ showedInfo: showInfo, isMap: isMap }"
  >
    <div class="main-container" v-bind:style="{ transform: transform }">
      <div class="top-promo">
        <div class="top-promo-holder">
          <span class="logo">
            <img
              v-if="langName === 'ru'"
              class="logo_image"
              src="../images/kvinta_ru.svg"
              alt="nfm logo"
            />
            <img
              v-else
              class="logo_image"
              src="../images/kvinta_ua.svg"
              alt="nfm logo"
            />

            <!-- <img src="../images/nfmlogo.svg" alt="nfm logo" /> -->
          </span>
          <span class="logo">
            <img
              class="logo_image"
              src="../images/massey-ferguson.svg"
              alt="nfm logo"
            />
          </span>
          <!-- <span class="logo-text" v-text="$ml.get('myLogoText')"></span> -->
        </div>
        <div class="map-btn-box" @click="tapMap" v-touch:swipe.bottom="tapMap">
          <div class="map-btn" v-if="!isMap">
            <span class="icon"></span>
            <span
              class="text"
              v-text="$ml.with('VueJS').get('mapTitle')"
            ></span>
          </div>
          <div class="map-icon-arrow">
            <i class="icon-map-arrow"></i>
          </div>
        </div>
      </div>
      <div class="map-section">
        <gmap-map
          ref="mainMap"
          :center="startLocation"
          :zoom="6"
          map-type-id="roadmap"
          style="width: 100%; height: 100%"
          :options="{
            zoomControl: false,
            scaleControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            disableDefaultUi: false,
          }"
        >
          <gmap-marker
            v-for="(item, key) in coordinates"
            :key="key"
            :position="getPosition(item)"
            :clickable="true"
            :icon="getMarkers(key)"
            @click="toggleInfo(item, key)"
          ></gmap-marker>
        </gmap-map>
        <transition name="map-info-window-slide">
          <div
            class="map-info-window"
            :opened="infoOpened"
            :position="infoPosition"
            v-if="infoOpened"
          >
            <div class="city-info">
              <div class="city-name">{{ selectedCity }}</div>
              <div class="city-name">{{ selectedAdress }}</div>
              <div class="city-phone">{{ selectedPhone }}</div>
              <div class="city-email">{{ selectedEmail }}</div>
              <div class="city-location">{{ selectedLocation }}</div>
              <!--              <button v-on:click="linkCordinat(infoPosition.lat, infoPosition.lng)" class="btn btn-full-width btn-main">{{$ml.with('VueJS').get('getDirections')}}</button>-->
              <a
                v-bind:href="
                  'https://www.google.com/maps/dir/?api=1&destination=' +
                  infoPosition.lat +
                  ',' +
                  infoPosition.lng
                "
                target="_blank"
                class="btn btn-full-width btn-main"
                >{{ $ml.with("VueJS").get("getDirections") }}</a
              >
              <div class="map-btn-close-holder">
                <button
                  class="map-btn-close"
                  @click="closeInfoWindow()"
                  v-touch:swipe.top="closeInfoWindow"
                ></button>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <nav class="main-menu-section">
        <!-- <a href="#/Catalog/0">
          <span class="icon icon-catalog">icon</span>
          <span class="text" v-text="$ml.with('VueJS').get('catalogTitle')">
            Каталог
            <br />техники
          </span>
        </a> -->
        <a href="https://kvinta-agro.com/catalogs">
          <span class="icon icon-catalog">icon</span>
          <span class="text" v-text="$ml.with('VueJS').get('catalogTitle')">
            Каталог
            <br />техники
          </span>
        </a>
        <a href="#/Search">
          <span class="icon icon-spare-part">icon</span>
          <span class="text" v-text="$ml.with('VueJS').get('sparePartTitle')">
            Запасные
            <br />части
          </span>
        </a>
        <a href="#/ServiceRequests">
          <span class="icon icon-order-services">icon</span>
          <span
            class="text"
            v-text="$ml.with('VueJS').get('orderServicesTitle')"
          >
            Заявки на
            <br />сервис
          </span>
        </a>
        <a href="#/Teaching">
          <span class="icon icon-teaching">icon</span>
          <span class="text" v-text="$ml.with('VueJS').get('teachingTitle')">
            Обучение
            <br />персонала
          </span>
        </a>
      </nav>
      <div
        class="information-btn-box"
        @click="tapInformation"
        v-touch:swipe.bottom="tapInformation"
        v-touch:swipe.top="tapInformation"
      >
        <div class="information-btn" v-bind:class="{ showedInfo: showInfo }">
          <span class="icon"></span>
          <span
            class="text"
            v-text="$ml.with('VueJS').get('informationTitle')"
          ></span>
        </div>
      </div>
    </div>
    <div
      class="phone-section"
      v-touch:tap="tapHandler"
      v-touch:swipe.left="swipeLeftHandler"
      v-touch:swipe.right="swipeRightHandler"
      v-bind:class="{ opened: isActive }"
    >
      <span class="phone-btn">tap</span>
      <div class="phone-panel">
        <div class="phone-panel-items">
          <div class="phone-panel-item">
            <div class="phone-panel-title">
              {{ $ml.with("VueJS").get("phone_new") }}
            </div>
            <a
              href="tel:+380673115052"
              style="color: #ffffff"
              class="phone-panel-value"
            >
              067 311 50 52
            </a>
          </div>
        </div>
        <div class="phone-panel-socials">
          <a
            href="https://www.instagram.com/kvintaagro/"
            class="phone-panel-socials-item"
          >
            <i class="icon-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/kvintaagro"
            class="phone-panel-socials-item"
          >
            <i class="icon-messenger"></i>
          </a>
        </div>
      </div>
    </div>
    <div
      class="information-section"
      v-bind:style="{ transform: transform }"
      ref="infoBox"
    >
      <div class="information-list">
        <a
          href="https://kvinta-agro.com/"
          target="_blank"
          class="information-item"
          >kvinta-agro.com</a
        >
        <a href="tel:+380673115052" class="information-item">(067) 311 50 52</a>
        <a
          href="#/Settings"
          class="information-item"
          v-text="$ml.with('VueJS').get('settingsTitle')"
          >Налаштування</a
        >
        <a
          href="#/Personal"
          class="information-item"
          v-text="$ml.with('VueJS').get('personalTitle')"
          >Особистий кабінет</a
        >
      </div>
      <!-- <div class="footer-menu">
        <a href="#/Kodeks" v-text="$ml.with('VueJS').get('codexTitle')"
          >Кодекс корпоративной этики</a
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { MLBuilder } from "vue-multilanguage";

export default {
  data() {
    localStorage.removeItem("search");
    let mapMarker = require("../images/ic-map-marker.svg");
    let mapMarkerActive = require("../images/ic-map-marker-active.svg");
    return {
      mapMarker,
      mapMarkerActive,
      nfm: "https://nfm.com.ua",
      isActive: false,
      showInfo: false,
      transform: "translateY(0)",
      heightString: 0,
      isMap: false,
      isMarkerClick: false,
      startLocation: {
        lat: 49.0384,
        lng: 33.4513,
      },
      coordinates: JSON.parse(localStorage.getItem("coordinates")),
      selectedAdress: null,
      selectedCity: null,
      selectedPhone: null,
      selectedEmail: null,
      selectedLocation: null,
      infoPosition: null,
      infoOpened: false,
      infoCurrentKey: null,
      markerOptions: {
        url: mapMarker,
      },
      selectedKey: null,
      selectedMarker: null,
      info: null,
      resultUser: null,
      langs: [{ lang: "Русский" }, { lang: "Українська" }],
      langName: "ru",
    };
  },
  mounted() {
    // console.log(this.linkCordinat(this.infoPosition.lat, this.infoPosition.lng))
    this.loadDepartments();
    this.isLang(localStorage.activeItem);
    this.loadJsonUser();
    this.checkAuth();
  },
  created() {
    console.log({ ACTIVEITEM: localStorage.getItem("activeItem") });
    if (localStorage.getItem("activeItem") === null) {
      localStorage.setItem("lang", this.langs[0].lang);
      localStorage.setItem("activeItem", 0);
    }
    if (localStorage.getItem("activeItem") === "1") {
      this.resource = this.$resource("departments/ua");
      this.langName = "ua";
    } else {
      this.resource = this.$resource("departments");
      this.langName = "ru";
    }
  },
  computed: {
    mlmyLogoText() {
      return new MLBuilder("logoText");
    },
  },
  methods: {
    async loadJsonUser() {
      if (localStorage.userId) {
        // this.users = this.$resource(
        //   "https://nfm.com.ua/index.php?route=api/app/users/" +
        //     localStorage.userId
        // );
        this.users = this.$resource(
          "https://dev3221.nfm.com.ua/index.php?route=api/app/users/" +
            localStorage.userId
        );
        await this.users
          .get()
          .then((response) => response.json())
          .then((response) => {
            if (!response.can_use_app) {
              localStorage.clear();
              return;
            }
            localStorage.setItem("userName", response.name);
            localStorage.setItem("userPhone", response.phone);
            localStorage.setItem("userEmail", response.email);
            localStorage.setItem("userRegion", response.region.name);
            localStorage.setItem("userNumber", response.egrpou);
            localStorage.setItem("userCompany", response.company_name);
          });
      }
    },
    linkCordinat(shirota, dolgota) {
      window.open(
        "https://www.google.com/maps/dir/?api=1&destination=" +
          shirota +
          "," +
          dolgota,
        "_blank"
      );
    },
    semeMet(name, value) {
      this.$localStorage.set(name, value);
    },
    async loadDepartments() {
      // if (localStorage.getItem("lang") === "Українська") {
      //   this.coordinates = [
      //     {
      //       id: "1",
      //       lat: "46.9721763",
      //       lng: "32.0710635",
      //       name: "Миколаївська область",
      //       phone: "095 057 05 36, 067 512 96 94",
      //       address: "м. Миколаїв, вул. Троїцька, 242",
      //       email: "nakonechniy.a@kvinta-agro.com"
      //     },
      //     {
      //       id: "2",
      //       lat: "48.4995483",
      //       lng: "32.3076811",
      //       name: "Кіровоградська область",
      //       phone: "067 610 89 06, 050 361 03 48",
      //       address: "м. Кропивницький, вул. Генерала Родимцева, 89",
      //       email: "brinza.r@kvinta-agro.com"
      //     }
      //   ];
      // } else {
      //   this.coordinates = [
      //     {
      //       id: "1",
      //       lat: "46.9721763",
      //       lng: "32.0710635",
      //       name: "Миколаївська область",
      //       phone: "095 057 05 36, 067 512 96 94",
      //       address: "г. Николаев, ул. Троицкая, 242",
      //       email: "nakonechniy.a@kvinta-agro.com"
      //     },
      //     {
      //       id: "2",
      //       lat: "48.4995483",
      //       lng: "32.3076811",
      //       name: "Кіровоградська область",
      //       phone: "067 610 89 06, 050 361 03 48",
      //       address: "г Кропивницкий, ул. Генерала Родимцева, 89",
      //       email: "brinza.r@kvinta-agro.com"
      //     }
      //   ];
      // }
      // localStorage.setItem("coordinates", JSON.stringify(this.coordinates));

      await this.resource
        .get()
        .then((response) => response.json())
        .then((response) => {
          response.forEach(function (values, item) {
            let phone = response[item].phone.join(", ");
            response[item].phone = phone;
          });
          this.coordinates = response;
          localStorage.setItem("coordinates", JSON.stringify(this.coordinates));
        })
        .catch(() => {
          this.coordinates = [
            {
              id: "1",
              lat: "46.9721763",
              lng: "32.0710635",
              name: "Миколаївська область",
              phone: "095 057 05 36, 067 512 96 94",
              address: "г. Николаев, ул. Троицкая, 242",
              email: "nakonechniy.a@kvinta-agro.com",
            },
            {
              id: "2",
              lat: "48.4995483",
              lng: "32.3076811",
              name: "Кіровоградська область",
              phone: "067 610 89 06, 050 361 03 48",
              address: "г Кропивницкий, ул. Генерала Родимцева, 89",
              email: "brinza.r@kvinta-agro.com",
            },
          ];
          localStorage.setItem("coordinates", JSON.stringify(this.coordinates));
        });
      // await this.resource.get().then(response => response.json())
      //   .then(response => (this.coordinates = response))
      // return this.coordinates
    },
    tapHandler() {
      this.isActive ? (this.isActive = false) : (this.isActive = true);
    },
    swipeLeftHandler() {
      this.isActive = true;
    },
    swipeRightHandler() {
      this.isActive = false;
    },
    gettingHeight() {
      this.heightString = this.$refs.infoBox.clientHeight;
    },
    tapInformation() {
      this.gettingHeight();
      // this.showInfo
      //   ? ((this.showInfo = false)
      //   (this.transform = 'translateY(0)'))
      //   : ((this.showInfo = true)
      //   (this.transform = 'translateY(-' + this.heightString + 'px)'))
      if (this.showInfo) {
        this.showInfo = false;
        this.transform = "translateY(0)";
      } else {
        this.showInfo = true;
        this.transform = "translateY(-" + this.heightString + "px)";
      }
    },
    tapMap() {
      this.isMap = !this.isMap;
    },
    getPosition: function (marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
    },
    getMarkers(key) {
      if (this.selectedKey === key) return this.mapMarkerActive;
      return this.mapMarker;
    },
    toggleInfo: function (marker, key) {
      this.infoPosition = this.getPosition(marker);
      // this.startLocation.lat = parseFloat(marker.lat)+1;
      // this.startLocation.lng = parseFloat(marker.lng);
      this.selectedPhone = "(067) 311 50 52";
      if (marker.phone) {
        this.selectedPhone = marker.phone;
      }

      this.selectedAdress = marker.address;
      this.selectedCity = marker.city;
      //this.selectedEmail = "sales@nfm.com.ua";
      this.selectedEmail = marker.email;
      this.selectedLocation = marker.full_name;
      if (this.infoCurrentKey === key) {
        if (this.infoOpened) {
          this.infoOpened = false;
          this.selectedKey = null;
        } else {
          this.infoOpened = true;
          this.selectedKey = key;
        }
        // this.infoOpened
        //   ? ((this.infoOpened = false), (this.selectedKey = null))
        //   : ((this.infoOpened = true), (this.selectedKey = key))
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
        this.selectedKey = key;
      }
    },
    closeInfoWindow: function () {
      this.infoOpened = false;
      this.selectedKey = null;
    },
    isLang: function (lang) {
      if (lang === "0") {
        this.$ml.change("ru");
      } else if (lang === "1") {
        this.$ml.change("ua");
      }
    },
    checkAuth: function () {
      if (localStorage.userId) {
        // this.users = this.$resource(
        //   "https://nfm.com.ua/index.php?route=api/app/users/" +
        //     localStorage.userId
        // );
        this.users = this.$resource(
          "https://dev3221.nfm.com.ua/index.php?route=api/app/users/" +
            localStorage.userId
        );
        this.users
          .get()
          .then((response) => response.json())
          .then((response) => {
            if (typeof response === "object" && response.length === 0) {
              localStorage.clear();
            }
          });
      }
    },
  },
};
</script>
